<!-- citationPush -->
<template>
  <div class="w center_all citationPush">
    <div class="center_2">
      <div class="header">
        <ul class="crumbs clearfix">
          <li>邮件推送</li>
          <li>-></li>
          <li>引证推送</li>
        </ul>
      </div>
      <div class="content-box">
        <div class="btn-box">
          <el-button class="new-task" @click="toCitationPushConfig">
            新建
          </el-button>
        </div>
        <SimpleTable
          ref="taskList"
          showOperate
          showOperateFixed="right"
          :showBtn1="false"
          showBtn2
          showBtn3
          showBtn4
          btn1Text="正式推送"
          btn3Text="删除"
          btn4Text="查看学者/效果预览"
          showOperateWidth="260px"
          :tableData="tableData"
          :columnData="tableColumn"
          :initObj="tablePageObj"
          @onClickBtn1="handleBtn1Click"
          @onClickBtn2="handleBtn2Click"
          @onClickBtn3="handleBtn3Click"
          @onClickBtn4="handleBtn4Click"
          @onChangePageData="
            (pageObj) => {
              getTableList(pageObj)
            }
          "
        />
      </div>
    </div>
    <citationPushDialog
      ref="citationPushDialog"
      @pushSuccess="getTableList"
    ></citationPushDialog>
    <el-dialog
      title="查看学者/效果预览"
      :visible.sync="checkScholarDialog"
      width="400px"
      :show-close="true"
      class="citationPush-scholar-dialog"
    >
      <h3>{{ currentTopic }}</h3>
      <div class="num-btn">
        <div class="left">
          <div class="num">
            推送学者数:
            <span v-loading="numberLoading">{{ userAllNumber || '--' }}</span>
          </div>
        </div>
        <div class="right">
          <el-button
            @click="refleshScholar(item)"
            type="small"
            :disabled="numberLoading || (currentItem && currentItem.status == 1)"
          >
            刷新学者
          </el-button>
          <el-button
            @click="toCitationPushDetails(item)"
            type="small"
            :disabled="!(currentResult && currentResult.status == 1)"
          >
            效果预览
          </el-button>
        </div>
      </div>
      <div class="num userRemainNumber">
        可推送学者数:
        <span v-loading="numberLoading">{{ userRemainNumber || '--' }}</span>
      </div>
      <div class="time">刷新学者时间：{{ refleshAuthorTime || '--' }}</div>
      <p>说明：学者计算完成后可预览本次推送效果</p>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetCitationList,
  DeleteCitation,
  CheckInitCitationAuthorTask,
  InitPushMagCitationHistoryAuthor,
} from '@/api/citationPush'
import { mapGetters } from 'vuex'
import SimpleTable from '@/components/SimpleTable'
import citationPushDialog from './components/citationPushDialog'

export default {
  name: 'citationPush',
  data() {
    return {
      tableData: [],
      tableColumn: [
        {
          title: '推送标题',
          prop: 'topic',
          notSortable: true,
        },
        {
          title: '推送时间',
          prop: 'pushTime',
          notSortable: true,
        },
        {
          title: '推送人',
          prop: 'operator',
          notSortable: true,
        },
        {
          title: '推送范围',
          prop: 'pushCount',
          notSortable: true,
        },
        {
          title: '已推送数',
          prop: 'pushSuccessCount',
          notSortable: true,
        },
        {
          title: '打开数',
          prop: 'emailOPenCount',
          notSortable: true,
        },
        {
          title: '唯一打开数',
          prop: 'onlyEmailOPenCount',
          notSortable: true,
          width: '120px',
        },
        {
          title: '打开率',
          prop: 'openRate',
          notSortable: true,
        },
        {
          title: '点击数',
          prop: 'readArticleCount',
          notSortable: true,
        },
        {
          title: '唯一点击数',
          prop: 'onlyReadArticleCount',
          notSortable: true,
          width: '120px',
        },
        {
          title: '点击率',
          prop: 'clickRate',
          notSortable: true,
        },
        {
          title: '今日状态',
          prop: 'statusText',
          notSortable: true,
        },
      ],
      tablePageObj: {
        page: 1,
        size: 10,
      },
      checkScholarDialog: false,
      numberLoading: true,
      userAllNumber: '',
      userRemainNumber: '',
      refleshAuthorTime: '',
      currentTopic: '',
      currentItem: '',
      currentResult: null,
    }
  },
  props: {},
  components: { SimpleTable, citationPushDialog },
  computed: {
    ...mapGetters({
      magId: 'magId',
    }),
  },
  mounted() {
    this.getTableList()
  },
  watch: {
    magId() {
      this.getTableList()
    },
  },
  methods: {
    getTableList(pageParams = {}) {
      let { page = 1, size = 10 } = pageParams
      let params = {
        page: page - 1,
        size,
        magId: this.magId,
      }
      GetCitationList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.tableData = data.content
          this.tablePageObj = {
            ...this.tablePageObj,
            page,
            size,
            total: data.totalElements,
          }
          if (this.tableData.length > 0) {
            this.tableData.map((item) => {
              let rate = (item.onlyEmailOPenCount || 0) / item.pushSuccessCount
              item.openRate = rate ? (rate * 100).toFixed(2) : 0
              let rate1 =
                (item.onlyReadArticleCount || 0) / item.pushSuccessCount
              item.clickRate = rate1 ? (rate1 * 100).toFixed(2) : 0
              item.statusText =
                item.status == 0
                  ? '待发送'
                  : item.status == 1
                  ? '推送中'
                  : item.status == 2
                  ? '已试推送'
                  : item.status == 3
                  ? '已推送'
                  : ''
              item.isHideBtn1 = item.status != 0
              item.btn2Text =
                item.status == 0
                  ? '编辑'
                  : item.status == 1 || item.status == 3
                  ? '详情'
                  : ''
              item.isHideBtn3 = item.status != 0
            })
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    handleBtn1Click(item) {
      this.$refs.citationPushDialog.dialogPushVisible = true
      this.$refs.citationPushDialog.currentTask = item
    },
    handleBtn2Click(item) {
      console.log(item, 'item')
      if (item.status == 0) {
        let routeData = this.$router.resolve({
          name: 'citationPushConfig',
          query: {
            type: 'edit',
            id: item.id,
          },
        })
        window.open(routeData.href, '_blank')
      } else if (item.status == 1 || item.status == 3) {
        let routeData = this.$router.resolve({
          name: 'citationPushConfig',
          query: {
            type: 'info',
            id: item.id,
          },
        })
        window.open(routeData.href, '_blank')
      }
    },
    handleBtn3Click(item) {
      this.$confirm('将删除选中信息，确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'btn-custom-cancel',
        type: 'warning',
      })
        .then(() => {
          DeleteCitation({
            id: item.id,
          }).then((res) => {
            if (res && res.data && res.data.status === 0) {
              this.$message({
                type: 'success',
                message: '删除成功',
                showClose: true,
              })
              this.getTableList()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            showClose: true,
          })
        })
    },
    handleBtn4Click(item) {
      this.checkScholarDialog = true
      this.currentItem = item
      this.currentTopic = item.topic
      CheckInitCitationAuthorTask({ historyId: item.id }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          let data = res.data.data
          this.currentResult = data
          if (!data) {
            this.numberLoading = false
          }
          if (data && data.status == 1) {
            this.numberLoading = false
            this.userAllNumber = data.countAllAuthorAndArticle
            this.userRemainNumber = data.countUnSendAuthorAndArticle
            this.refleshAuthorTime = data.lastRefreshTime
          }
          if (data && data.status == 0) {
            this.$message.info({
              message: '学者计算中',
            })
          }
        }
      })
    },
    toCitationPushConfig() {
      let routeData = this.$router.resolve({
        name: 'citationPushConfig',
        query: {
          type: 'new',
        },
      })
      window.open(routeData.href, '_blank')
    },
    toCitationPushDetails() {
      let routeData = this.$router.resolve({
        name: 'citationPushDetails',
        query: {
          taskId: this.currentItem.id,
          topic: this.currentItem.topic,
        },
      })
      window.open(routeData.href, '_blank')
    },
    refleshScholar() {
      InitPushMagCitationHistoryAuthor({ historyId: this.currentItem.id }).then(
        (res) => {
          if (res && res.data && res.data.status === 0) {
            this.numberLoading = true
            this.$message.info({
              message: '学者计算中',
            })
            this.checkScholarDialog = false
          }
        },
      )
    },
  },
}
</script>
<style lang="scss">
.citationPush {
  .operate-btns {
    .el-button {
      color: #2644ca;
    }
  }

  .content-box {
    .el-table__header {
      th {
        background: #f1f2f4 !important;
      }
    }

    .el-table__fixed-right {
      bottom: 0 !important;
    }
  }
}

.citationPush-scholar-dialog {
  text-align: left;

  .num-btn {
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;
  }

  .userRemainNumber,
  .time {
    margin-top: 10px;
  }
}
</style>
<style lang="scss" scoped>
.citationPush {
  .center_2 {
    min-height: 400px;

    .content-box {
      .btn-box {
        text-align: left;

        .new-task {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
